import React, { useState, useEffect, useCallback, useMemo } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css"; // Ensure to add relevant CSS as per the instructions

function ShibariTechniqueDriller() {
  const [techniques, setTechniques] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false); // State for the scroll button visibility
  const [filteredTechniques, setFilteredTechniques] = useState([]);
  const [displayedTechniques, setDisplayedTechniques] = useState([]); // For techniques being displayed
  const [techniquesPerLoad] = useState(30); // Number of techniques to load per batch
  const [currentPage, setCurrentPage] = useState(1); // Current page of techniques being displayed
  const [filters, setFilters] = useState({
    authors: "All",
    platforms: "All",
    searchQuery: "",
  });
  const [expandedTechnique, setExpandedTechnique] = useState(null); // Track which technique is expanded

  // Fetch techniques from the external JSON file
  useEffect(() => {
    fetch("/kyohon.json")
      .then((response) => response.json())
      .then((data) => {
        const shuffledData = shuffleArray(data); // Shuffle the data on initial load
        setTechniques(shuffledData);
        setFilteredTechniques(shuffledData); // Initially show all techniques in random order
        setDisplayedTechniques(shuffledData.slice(0, techniquesPerLoad)); // Display initial batch in random order
      })
      .catch((error) => console.error("Error fetching techniques:", error));
  }, [techniquesPerLoad]);

  // Memoize unique values for dropdowns and sort them alphabetically
  const uniqueValues = useMemo(() => {
    return {
      authors: ["All", ...new Set(techniques.map((t) => t.author))].sort(),
      platforms: ["All", ...new Set(techniques.map((t) => t.platform))].sort(),
    };
  }, [techniques]);

  function ScrollToTopButton() {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scroll effect
      });
    };

    return (
      <button className="scroll-to-top" onClick={scrollToTop}>
        ↑ Back to Top
      </button>
    );
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true); // Show the button after scrolling 300px
      } else {
        setShowScrollButton(false); // Hide the button when near the top
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  // Shuffle the filtered techniques when the header is clicked
  const handleHeaderClick = () => {
    const shuffledTechniques = shuffleArray([...filteredTechniques]);
    setFilteredTechniques(shuffledTechniques); // Update the displayed techniques with the shuffled array
    setDisplayedTechniques(shuffledTechniques.slice(0, techniquesPerLoad)); // Reset the displayed techniques with the shuffled data
    setCurrentPage(1); // Reset the current page to 1
  };

  // Handle filter changes, including search query
  const handleFilterChange = useCallback((filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
    setCurrentPage(1); // Reset pagination when filters are changed
  }, []);

  // Helper function to search across multiple fields
  const matchesSearchQuery = (technique, query) => {
    const lowerCaseQuery = query.toLowerCase();
    const nameMatches = technique.name?.toLowerCase().includes(lowerCaseQuery);
    const descriptionMatches = technique.description
      ? technique.description.toLowerCase().includes(lowerCaseQuery)
      : false;
    const tagsMatches = technique.tags
      ? technique.tags.some((tag) => tag.toLowerCase().includes(lowerCaseQuery))
      : false;

    return nameMatches || descriptionMatches || tagsMatches;
  };

  // Filter techniques based on the selected filters and search query
  const filterTechniques = useCallback(() => {
    return techniques.filter((technique) => {
      const matchesAuthor =
        filters.authors === "All" || technique.author === filters.authors;
      const matchesPlatform =
        filters.platforms === "All" || technique.platform === filters.platforms;
      const matchesSearchQueryField =
        filters.searchQuery === "" ||
        matchesSearchQuery(technique, filters.searchQuery);

      return matchesAuthor && matchesPlatform && matchesSearchQueryField;
    });
  }, [techniques, filters]);

  // Re-filter techniques whenever filters or techniques change
  useEffect(() => {
    const newFilteredTechniques = filterTechniques();
    setFilteredTechniques(newFilteredTechniques);
    setDisplayedTechniques(newFilteredTechniques.slice(0, techniquesPerLoad)); // Display the first batch
    setCurrentPage(1); // Reset to the first page
  }, [filterTechniques, techniquesPerLoad]);

  // Function to load more techniques when scrolling
  const loadMoreTechniques = useCallback(() => {
    const nextPage = currentPage + 1;
    const nextTechniques = filteredTechniques.slice(
      0,
      nextPage * techniquesPerLoad
    );
    setDisplayedTechniques(nextTechniques);
    setCurrentPage(nextPage);
  }, [currentPage, filteredTechniques, techniquesPerLoad]);

  // Scroll event listener to detect when to load more techniques
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight - 50) {
        loadMoreTechniques();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreTechniques]);

  // Handle expanding/collapsing techniques
  const handleExpandTechnique = (techniqueUrl) => {
    if (expandedTechnique === techniqueUrl) {
      setExpandedTechnique(null);
    } else {
      setExpandedTechnique(techniqueUrl);
    }
  };

  return (
    <div>
      <div onClick={handleHeaderClick} style={{ cursor: "pointer" }}>
        <Header />
      </div>
      <div className="app-container">
        {/* Search Box */}
        <div className="search-box">
          <input
            type="text"
            placeholder="Search..."
            value={filters.searchQuery}
            onChange={(event) =>
              handleFilterChange("searchQuery", event.target.value)
            }
          />
        </div>

        {/* Filter Options */}
        <div className="filter-container">
          <Dropdown
            label="Author"
            options={uniqueValues.authors}
            selected={filters.authors}
            onChange={(event) =>
              handleFilterChange("authors", event.target.value)
            }
          />
          <Dropdown
            label="Platform"
            options={uniqueValues.platforms}
            selected={filters.platforms}
            onChange={(event) =>
              handleFilterChange("platforms", event.target.value)
            }
          />
        </div>
        <div className="count">
          {filteredTechniques.length} of {techniques.length}
        </div>
        {/* List of Displayed Techniques */}
        <div className="technique-list">
          {displayedTechniques.length > 0 ? (
            displayedTechniques.map((technique) => (
              <Technique
                key={technique.url}
                technique={technique}
                isExpanded={expandedTechnique === technique.url}
                onExpand={handleExpandTechnique}
              />
            ))
          ) : (
            <p>No techniques match your filter.</p>
          )}
        </div>
        {showScrollButton && <ScrollToTopButton />}
      </div>
      <Footer />
    </div>
  );
}

// Technique component - Loads details from the technique object
function Technique({ technique, isExpanded, onExpand }) {
  const { name, author, platform, description, url, tags, image } = technique;

  return (
    <>
      {/* Title */}
      <div className="technique-item" onClick={() => onExpand(technique.url)}>
        <h2 className="technique-title">{name}</h2>

        <h3 className="technique-author">{author !== "Unknown" && author}</h3>
        {image && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img
              src={`./meta_images/${image}`}
              alt={name}
              className="preview-image"
            />
          </a>
        )}
        <div className="technique-content">
          <p className="technique-description">
            {description || "No description available"}
          </p>

          {tags && tags.length > 0 && (
            <p className="technique-tags">{tags.join(" - ")}</p>
          )}
          <a
            className="learn-more-btn"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on {platform}
          </a>
        </div>
      </div>
      <hr />
    </>
  );
}

// Dropdown component
function Dropdown({ label, options, selected, onChange }) {
  return (
    <div className="dropdown-container">
      <label>{label}</label>
      <select value={selected} onChange={onChange}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ShibariTechniqueDriller;
